<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import toast from 'svelte-french-toast';

    import CheckoutDialog from '@/elements/checkout/CheckoutDialog.svelte';

    import HeartIcon from '@/Icons/HeartIcon.svelte';
    import ChatBubbleOvalIcon from '@/Icons/ChatBubbleOvalIcon.svelte';
    import GiftIcon from '@/Icons/GiftIcon.svelte';

    export let post: any;
    export let currentUser: any;
    export let data: any;
    export let routeName: string;
    export let PostsPaginator: any;
    export let totalComments: number = 0;
    export let loggedUserAvailableAmount;
    export let creatorCanEarnMoney: boolean;
    export let paymentSettings;

    $: commentsCount = totalComments;
    $: commentsLabel = commentsCount === 1 ? 'comment' : 'comments';

    const dispatch = createEventDispatcher();
    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenMeta ? csrfTokenMeta.getAttribute('content') || '' : '';

    export let isPostUnlocked: () => boolean;
    export let canShowTipButton: () => boolean;

    let isLoading = false;
    $: reactionsCount = post.reactions.length;
    $: reactionsLabel = reactionsCount === 1 ? 'like' : 'likes';

    let showCheckoutDialog = false;

    $: effectiveCreatorCanEarnMoney =
        data.creatorCanEarnMoney !== undefined ? data.creatorCanEarnMoney : creatorCanEarnMoney;

    async function handleReactTo() {
        if (isLoading) return;

        isLoading = true;
        const type = 'post';
        const id = post.id;
        const didReact = data.didUserReact;

        data.didUserReact = !didReact;
        post.reactions = didReact ? post.reactions.slice(0, -1) : [...post.reactions, { id: 'temp' }];
        reactionsCount = post.reactions.length;
        reactionsLabel = reactionsCount === 1 ? 'like' : 'likes';

        const formData = new FormData();
        formData.append('type', type);
        formData.append('action', didReact ? 'remove' : 'add');
        formData.append('id', id.toString());

        try {
            const response = await fetch(`${app.baseUrl}/posts/reaction`, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            if (!result.success) {
                throw new Error(result.errors?.[0] || 'An error occurred');
            }
        } catch (error) {
            data.didUserReact = didReact;
            post.reactions = didReact ? [...post.reactions, { id: 'temp' }] : post.reactions.slice(0, -1);
            reactionsCount = post.reactions.length;
            reactionsLabel = reactionsCount === 1 ? 'like' : 'likes';

            toast.error(error instanceof Error ? error.message : 'An error occurred');
        } finally {
            isLoading = false;
        }
    }

    function handleShowPostComments() {
        dispatch('toggleComments', { postId: post.id });
    }

    function handleGoToPostPageKeepingNav() {
        PostsPaginator.goToPostPageKeepingNav(post.id, post.postPage, `/posts/${post.id}/${post.user.username}`);
    }

    function handleShowCheckoutDialog() {
        showCheckoutDialog = true;
    }

    $: isConverting =
        currentUser &&
        ((post.user_id === currentUser.id && post.status == 0 && data.hasVideoAttachment) ||
            (currentUser.role_id === 1 && post.status == 0 && data.hasVideoAttachment));
</script>

<div class="post-footer mt-3 pl-3 pr-3" class:converting="{isConverting}">
    <div class="footer-actions flex justify-between">
        <div class="d-flex">
            <!-- Likes -->
            {#if isPostUnlocked()}
                <div
                    class="h-pill h-pill-primary react-button mr-1 rounded hover:text-bmn-500"
                    class:text-bmn-500="{data.didUserReact}"
                    class:loading="{isLoading}"
                    on:click="{handleReactTo}"
                    title="{data.didUserReact ? 'Unlike' : 'Like'}"
                >
                    <HeartIcon filled="{data.didUserReact}" />
                </div>
            {:else}
                <div class="h-pill h-pill-primary react-button disabled mr-1 rounded">
                    <HeartIcon filled="{false}" />
                </div>
            {/if}

            <!-- Comments -->
            {#if routeName !== 'posts.get'}
                {#if isPostUnlocked()}
                    <div
                        class="h-pill h-pill-primary mr-1 rounded"
                        on:click="{handleShowPostComments}"
                        title="Show comments"
                    >
                        <ChatBubbleOvalIcon />
                    </div>
                {:else}
                    <div class="h-pill h-pill-primary disabled mr-1 rounded">
                        <ChatBubbleOvalIcon />
                    </div>
                {/if}
            {/if}

            <!-- Tips -->
            {#if canShowTipButton()}
                {#if isPostUnlocked()}
                    <div
                        class="h-pill h-pill-primary send-a-tip cursor-pointer"
                        class:disabled="{!effectiveCreatorCanEarnMoney}"
                        on:click="{handleShowCheckoutDialog}"
                    >
                        <GiftIcon />
                        <span class="d-none d-lg-block ml-1">Send a tip</span>
                    </div>
                {:else}
                    <div class="h-pill h-pill-primary send-a-tip disabled">
                        <GiftIcon />
                        <span class="ml-1 hidden md:block">Send a tip</span>
                    </div>
                {/if}
            {/if}
        </div>

        <div class="post-count-details mt-0 flex items-center justify-center">
            <span class="ml-2-h">
                <strong class="post-reactions-label-count font-bold">{reactionsCount}</strong>
                <span class="post-reactions-label">{reactionsLabel}</span>
            </span>
            {#if isPostUnlocked()}
                <span class="ml-2-h hidden lg:block">
                    <a
                        href="{routeName !== 'getPost'
                            ? `/posts/${post.id}/${post.user.username}#comments`
                            : '#comments'}"
                        class="text-dark-r text-hover dark:!text-white"
                        on:click|preventDefault="{handleGoToPostPageKeepingNav}"
                    >
                        <strong class="post-comments-label-count">{post.comments_count}</strong>
                        <span class="post-comments-label">{post.comments_count === 1 ? 'comment' : 'comments'}</span>
                    </a>
                </span>
            {:else}
                <span class="ml-2-h hidden lg:block">
                    <strong class="post-comments-label-count">{post.comments_count}</strong>
                    <span class="post-comments-label">{post.comments_count === 1 ? 'comment' : 'comments'}</span>
                </span>
            {/if}

            <span class="ml-2-h hidden lg:block">
                <strong class="post-tips-label-count">{post.tips_count}</strong>
                <span class="post-tips-label">{post.tips_count === 1 ? 'tip' : 'tips'}</span>
            </span>
        </div>
    </div>
</div>

<CheckoutDialog
    bind:show="{showCheckoutDialog}"
    user="{post.user}"
    {data}
    {paymentSettings}
    authenticatedUser="{currentUser}"
    userAvailableCredit="{data.loggedUserAvailableAmount ?? loggedUserAvailableAmount}"
    transactionType="tip"
/>

<style>
    .react-button {
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .react-button.disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    .react-button.loading {
        opacity: 0.8;
        pointer-events: none;
    }
</style>
